import React from 'react'
import Layout from 'src/components/Layout'
import { Helmet } from 'react-helmet'
import peg from 'src/pegTheme'
import styled from '@emotion/styled'

import orange from 'src/images/orange.svg'
import { SubHeading, StrapLine } from 'src/components/typography'

export default () => (
  <Layout>
    <Helmet>
      <title>404 | YouTube & Instagram Influencer Marketing Tool & Platform | Peg</title>
    </Helmet>

    <NotFoundSection>
      <ErrorIcon src={ orange } alt='' />
      <SubHeading>Hmm, we couldn't find that page</SubHeading>
      <StrapLine>
        It was probably this evil orange's fault.
        If you need some help, just drop us a line at <a href='mailto:hello@peg.co'>hello@peg.co</a>.
      </StrapLine>
    </NotFoundSection>
  </Layout>
)

const NotFoundSection = styled.section`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
`

const ErrorIcon = styled('img')`
  width: 150px;
  margin: ${ peg.spacing('xl') } 0;
`